<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      v-if="
        getProfileInfo &&
        getProfileInfo.permissions.includes(item.role)
      "
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils';
import { provide, ref } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getProfileInfo: 'getProfileInfo',
    }),
  },
  watch: {
    getProfileInfo: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          const loginData = this.$cookies.get('super-login-data', {
            parseJSON: true,
          });
          if (newVal.status != 1 && this.$route.name != 'dashboard') {
            return this.$router.push({
              name: 'dashboard',
              params: { lang: this.$i18n.locale, role: loginData.account_type },
            });
          }
          if (newVal.status === 3) {
            this.$router.push({
              name: 'CompleteRegistration',
              params: {
                lang: this.$i18n.locale,
                role: loginData.account_type,
              },
            });
          }
        }
      },
    },
  },
  setup() {
    provide('openGroups', ref([]));

    return {
      resolveNavItemComponent,
    };
  },
};
</script>
