var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded"},[_vm._t("header",function(){return [_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('b-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('span',{staticClass:"brand-logo"},[_c('logo-dark',{staticClass:"menu-expanded-block"}),_c('logo-dark-2',{staticClass:"menu-collapsed-block d-none"})],1)])],1),_c('li',{staticClass:"nav-item nav-toggle"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle"},[_c('menu-icon',{staticClass:"d-block d-xl-none",on:{"click":_vm.toggleVerticalMenuActive}}),_c(_vm.collapseTogglerIconFeather,{tag:"component",staticClass:"d-none d-xl-block collapse-toggle-icon",class:_vm.collapseTogglerIconFeather,on:{"click":_vm.toggleCollapsed}})],1)],1)])]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"\n      main-menu-content\n      scroll-area\n      d-flex\n      flex-column\n      justify-content-between\n    ",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.navMenuItems}}),_c('ul',{staticClass:"navigation navigation-main"},[_c('li',{staticClass:"nav-item",on:{"click":function($event){_vm.showLogout = true}}},[_c('a',{staticClass:"d-flex align-items-center"},[_c('logout-icon',{tag:"component",class:'logout-icon'}),_c('span',{staticClass:"menu-title text-truncate text-bold-16"},[_vm._v(_vm._s(_vm.$t('common.logout')))])],1)])]),_c('warning-modal',{attrs:{"variant":"danger","variant2":"danger","icon-name":"logout2-icon","visible":_vm.showLogout,"title":_vm.logoutTitle,"sub-title":_vm.logoutMessage,"btn-title":_vm.logoutTitle},on:{"close":function($event){_vm.showLogout = false},"submitAction":function($event){return _vm.logout()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }