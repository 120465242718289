/* eslint-disable */
// prettier-ignore
import i18n from '@/libs/i18n';

export default {
  pages: {
    key: 'title',
    data: [
      {
        title: i18n.t('nav.dashboard'),
        route: { name: 'dashboard' },
        icon: 'home-icon',
        isBookmarked: false,
      },
      {
        title: i18n.t('nav.users'),
        route: { name: 'users' },
        icon: 'users-nav-icon',
        isBookmarked: false,
      },
      {
        title: i18n.t('nav.orders'),
        route: { name: 'orders' },
        icon: 'orders-icon',
        isBookmarked: false,
      },
      {
        title: i18n.t('nav.offers'),
        route: { name: 'offers' },
        icon: 'offers-icon',
        isBookmarked: false,
      },
      {
        title: i18n.t('nav.projects'),
        route: { name: 'projects' },
        icon: 'projects-icon',
        isBookmarked: false,
      },
      {
        title: i18n.t('nav.financial_operations'),
        route: { name: 'financial' },
        icon: 'status-up-icon',
        isBookmarked: false,
      },
      {
        title: i18n.t('nav.reviews'),
        route: { name: 'reviews' },
        icon: 'reviews-icon',
        isBookmarked: false,
      },
      {
        title: i18n.t('nav.settings'),
        route: { name: 'settings' },
        icon: 'setting-icon',
        isBookmarked: false,
      },
    ],
  },
  // files: {
  //     key: "file_name",
  //     data: [
  //         {
  //             file_name: "Joe's CV",
  //             from: "Stacy Watson",
  //             icon: require("@/assets/images/logo/logo.svg"),
  //             size: "1.7 mb",
  //         },
  //     ],
  // },
  // contacts: {
  //     key: "name",
  //     data: [
  //         {
  //             img: require("@/assets/images/logo/logo.svg"),
  //             name: "Rena Brant",
  //             email: "nephrod@preany.co.uk",
  //             time: "21/05/2019",
  //         },
  //     ],
  // },
};
/* eslint-enable */
