export default [
    {
      title: 'Logout',
      icon: 'logout-icon', 
      tagVariant: 'light-warning',
      route: '',
    
    },
    
  ]
  