<template>
  <b-nav-item-dropdown
    v-if="getProfileInfo"
    right
    toggle-class="d-flex align-items-center dropdown-user-link px-0"
    class="dropdown-user"
  >
    <template #button-content>
      <img
        v-if="getProfileInfo.photo"
        :src="getProfileInfo.photo.full_path + getProfileInfo.photo.file_name"
        alt=""
        class="company-logo"
      >
      <b-avatar
        v-else
        size="50"
        variant="light-primary"
        class="company-logo"
        badge-variant="success"
      >
        <default-user-image-icon />
      </b-avatar>

      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ getProfileInfo.name }}
        </p>
        <p
          class="user-name font-weight-bolder mb-0"
          style="line-height: 12px"
        >
          {{ getProfileInfo.user_role[0] }}
        </p>
        <!-- <span class="user-status">{{  getProfileInfo.user_type}}</span> -->
      </div>
      <div class="user-arrow-down">
        <arrow-down-icon />
      </div>
    </template>

    <b-dropdown-item
      :to="{ name: 'settings' }"
      link-class="d-flex align-items-center  "
    >
      <setting-icon class="setting-icon mr-3" />
      <span class="text-reg-14 text-secondary-color">{{
        $t('nav.settings')
      }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="showLogout = true"
    >
      <logout-icon class="logout-icon mr-3" />
      <span class="text-danger"> {{ $t('common.logout') }}</span>
    </b-dropdown-item>
    <warning-modal
      variant="danger"
      variant2="danger"
      icon-name="logout2-icon"
      :visible="showLogout"
      :title="$t('common.logout')"
      :sub-title="$t('common.logout_message')"
      :btn-title="$t('common.logout')"
      @close="showLogout = false"
      @submitAction="logout()"
    />
  </b-nav-item-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WarningModal from '@/components/WarningModal.vue';

export default {
  components: { WarningModal },
  data() {
    return {
      showLogout: false,
    };
  },
  computed: {
    ...mapGetters({
      getProfileInfo: 'getProfileInfo',
    }),
  },
  methods: {
    ...mapActions({ logout: 'logout' }),
  },
};
</script>
