export default [
  {
    title: 'dashboard',
    icon: 'home-icon',
    tagVariant: 'light-warning',
    route: 'dashboard',
    role: 'view_dashboard',
  },
  {
    title: 'users',
    icon: 'users-nav-icon',
    tagVariant: 'light-warning',
    route: 'users',
    role: 'view_users',
  },
  {
    title: 'orders',
    icon: 'orders-icon',
    tagVariant: 'light-warning',
    route: 'orders',
    role: 'view_orders',
  },
  {
    title: 'offers',
    icon: 'offers-icon',
    tagVariant: 'light-warning',
    route: 'offers',
    role: 'view_offers',
  },
  {
    title: 'projects',
    icon: 'projects-icon',
    tagVariant: 'light-warning',
    route: 'projects',
    role: 'view_projects',
  },
  {
    title: 'financial_operations',
    icon: 'status-up-icon',
    tagVariant: 'light-warning',
    route: 'financial',
    role: 'view_financial',
  },
  {
    title: 'reviews',
    icon: 'reviews-icon',
    tagVariant: 'light-warning',
    route: 'reviews',
    role: 'view_reviews',
  },
  {
    title: 'settings',
    icon: 'setting-icon',
    tagVariant: 'light-warning',
    route: 'settings',
    role: 'view_settings',
  },
];
