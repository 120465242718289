<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <menu-icon class="menu-icon" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="
        align-items-center
        flex-grow-1
        d-none d-lg-flex
        text-bold-26 text-font-main
      "
    >
      <wameed-search-header />
      <!-- يا أهلا و سهلا 👋 -->
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <LanguageSwitch />
      <search-bar />
      <!-- <messages /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue';
import wameedSearchHeader from '@/components/materials/WameedSearchHeader.vue';
import LanguageSwitch from './components/LanguageSwitch.vue';
import SearchBar from './components/SearchBar.vue';
import Messages from './components/Messages.vue';
import DarkToggler from './components/DarkToggler.vue';
import CartDropdown from './components/CartDropdown.vue';
import NotificationDropdown from './components/NotificationDropdown.vue';
import UserDropdown from './components/UserDropdown.vue';

export default {
  components: {
    wameedSearchHeader,
    LanguageSwitch,
    TextInput,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    Messages,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
